import React from "react"
// import { useInView } from "react-intersection-observer"
// import Circles from "./Circles"
// import { StaticQuery, graphql } from "gatsby"
// import Img from "gatsby-image"
// import { Link } from "gatsby"
import GratitudeJournals from "./Journals/GratitudeJournals"
import TimeCapsuleJournal from "./Journals/TimeCapsuleJournal"
import DrawingJournals from "./Journals/DrawingJournals"
import DiscoveryJournal from "./Journals/DiscoveryJournal"

export default function Products() {
  return (
    <section className="home-products">
      <GratitudeJournals />
      <TimeCapsuleJournal />
      <DrawingJournals />
      <DiscoveryJournal />
    </section>
  )
}
