import React, { useEffect, useState } from "react"
import Circles from "../../Circles"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link } from "gatsby"
// import { useInView } from "react-intersection-observer";

const Showcase = ({
  journal,
  // inView,
  motion,
  y1Gratitude,
  y2Gratitude,
  yDrawing,
  yClassic,
  rotateY1Anim,
  rotateY2Anim,
  rotateDrawingClassic,
  rotateDrawingNeo,
  yTimeCapsule,
  rotateTimeCapsule,
  yDiscovery,
  rotateDiscovery,
}) => {
  // console.log("journal", journal);

  return (
    <div className="home-products__showcase-container">
      <StaticQuery
        query={graphql`
          query {
            gratitude_neo: file(relativePath: { eq: "gratitude_neo.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            gratitude_classic: file(
              relativePath: { eq: "gratitude_classic.png" }
            ) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            drawing_classic: file(relativePath: { eq: "drawing_classic.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            drawing_neo: file(relativePath: { eq: "drawing_neo.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            time_classic: file(relativePath: { eq: "time_classic.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            discovery: file(relativePath: { eq: "discovery.png" }) {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <div
              className={`home-products__journal-img-container showcase-${journal}`}
            >
              {journal === "gratitude" && (
                <>
                  <motion.div
                    style={{ y: y1Gratitude, rotate: rotateY1Anim }}
                    // style={{ y: y1 }}
                    className={`home-products__journal-img  home-products__journal-gratitude-left`}
                  >
                    <Img fluid={data.gratitude_classic.childImageSharp.fluid} />
                  </motion.div>
                  <motion.div
                    style={{ y: y2Gratitude, rotate: rotateY2Anim }}
                    // style={{ y: y2 }}
                    className={`home-products__journal-img home-products__journal-gratitude-right `}
                  >
                    <Img fluid={data.gratitude_neo.childImageSharp.fluid} />
                  </motion.div>
                </>
              )}
              {journal === "time-capsule" && (
                <>
                  <motion.div
                    style={{ y: yTimeCapsule, rotate: rotateTimeCapsule }}
                    // style={{ y: yTimeCapsule }}
                    className={`home-products__journal-img home-products__journal-time-capsule`}
                  >
                    <Img fluid={data.time_classic.childImageSharp.fluid} />
                  </motion.div>
                </>
              )}
              {journal === "drawing" && (
                <>
                  <>
                    <motion.div
                      style={{ y: yClassic, rotate: rotateDrawingClassic }}
                      // style={{ y: y1 }}
                      className={`home-products__journal-img  home-products__journal-drawing-bottom home-products__journal-img-drawing`}
                    >
                      <Img fluid={data.drawing_classic.childImageSharp.fluid} />
                    </motion.div>
                    <motion.div
                      style={{ y: yDrawing, rotate: rotateDrawingNeo }}
                      // style={{ y: y2 }}
                      className={`home-products__journal-img home-products__journal-drawing-top home-products__journal-img-drawing`}
                    >
                      <Img fluid={data.drawing_neo.childImageSharp.fluid} />
                    </motion.div>
                  </>
                </>
              )}

              {journal === "discovery" && (
                <>
                  <motion.div
                    style={{ y: yDiscovery, rotate: rotateDiscovery }}
                    // style={{ y: yTimeCapsule }}
                    className={`home-products__journal-img home-products__journal-discovery`}
                  >
                    <Img fluid={data.discovery.childImageSharp.fluid} />
                  </motion.div>
                </>
              )}

              {/* {inView && <Circles journal={journal} />} */}
            </div>
          )
        }}
      />

      {/* <Link
        className={`home-products__showcase-button home-products__showcase-button-${journal}`}
        to="/products"
      >
        Explore
      </Link> */}
    </div>
  )
}

export default Showcase
