import "../styles/index.scss"
import React from "react"
// import Helmet from 'react-helmet';

import SEO from "../components/seo"

import Intro from "../components/Home/Intro"
import Body from "../components/Home/Body"
import Products from "../components/Home/Products"
import Header from "../components/HeaderConfirm"
import Helmet from "react-helmet"
import Footer from "../components/Footer"
import "normalize.css"
// import { useInView } from "react-intersection-observer";
// import { motion, useViewportScroll, useTransform } from "framer-motion";

const HomePage = props => {
  //   const { scrollY } = useViewportScroll();
  //   const y1 = useTransform(scrollY, [0, 1300], [0, 200]);
  //   const y2 = useTransform(scrollY, [0, 1300], [0, -100]);
  //   const { scrollYProgress } = useViewportScroll();
  //   const y1 = useTransform(scrollYProgress, [0, 0.5, 1], [100, -1000, -1800]);
  //   const y2 = useTransform(scrollYProgress, [0, 0.5, 1], [0, -500, -800]);
  // React.useEffect(() => {
  //   document.body.style.height = "auto";

  //   return () => {
  //     document.body.style.height = "100%";
  //   };
  // }, []);

  return (
    <React.Fragment>
      <SEO title="Cat & Bear" description="Lovely Little Journals" />

      <Helmet>
      <meta name="facebook-domain-verification" content="0iqcugv8qg3v9wr8f5w7atp1gaqk1j" />
      </Helmet>

      <Header
        path={props.location && props.location.pathname}
        color="#22b2f2"
      />
      <div className="home-container">
        <Intro />
        <Products />
        <Body />
        <Footer />
      </div>
    </React.Fragment>
  )
}

export default HomePage
